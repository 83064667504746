import React, { useEffect } from "react";
import { useEventID } from "wecall-config-lib";
import newslogo1 from "../assets/news-logo01.webp";
import newslogo2 from "../assets/news-logo02.webp";
import newslogo3 from "../assets/news-logo03.webp";
import newslogo4 from "../assets/news-logo04.webp";
import newslogo5 from "../assets/news-logo05.webp";
import newslogo6 from "../assets/news-logo06.webp";
import star from "../assets/Stars (3).webp";
import { Link, Outlet } from "react-router-dom";
import testimonial from "../assets/Frame 517.webp";
import user1 from "../assets/user-1.webp";
import user2 from "../assets/user-2.webp";

import InitialScripts from "../script/initialScripts";
import FloatingCard from "../floating-card";
import gasImage from "../assets/Frame 1000005938.webp";
import gasImage1 from "../assets/Group (1).webp";
import groceryImage from "../assets/Frame 1000005937.webp";
import groceryImage1 from "../assets/fi_1159679.webp";
import rentImage from "../assets/Frame 1000005939.webp";
import rentImage1 from "../assets/fi_965328-_1_.webp";
import shoppingImage from "../assets/Frame 1000005940.webp";
import shoppingImage1 from "../assets/fi_1159679.webp";
import Accordion from "../component/Accordion";

export default function DefaultPage({ setNumber, cityStateResponse }) {
  const { state, city, zip } = cityStateResponse;
  function gotoTop() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }
  const accordionData = [
    {
      id: "1",
      title: "1. Who can apply for the subsidy?",
      content: `Eligibility varies by program, typically based on income, employment status, or business size. Check our eligibility page for details.`,
    },
    {
      id: "2",
      title: "2. How do I apply?",
      content: `Fill out the application form on our website and submit the required documents. Visit our application guide for a step-by-step process.`,
    },
    {
      id: "3",
      title: "3. What documents are needed?",
      content: `Common documents include a government-issued ID, proof of income, and for businesses, proof of ownership and financial statements. See the full list on our documentation page.`,
    },
    {
      id: "4",
      title: "4. How long until I receive the subsidy?",
      content: `Processing usually takes 4-6 weeks. You can track your application status online or contact support for updates.`,
    },
  ];

  return (
    <>
      <InitialScripts setNumber={setNumber} />
      <div className="App">
        <div className="mainWrapper">
          <header>
            <div className="container">
              <div className="row">
                <div className="logo-wrapper">
                  <a href="#">{/* <img src={logo}></img> */}</a>
                </div>
              </div>
            </div>
          </header>
          <div className="hero-section">
            <div className="container">
              <div className="row">
                <Outlet />
              </div>
            </div>
          </div>

          <div className="card-section">
            <div className="container">
              <div className="row">
                <div className="custom-container">
                  <div className="icon-pack">
                    <div className="headline-back">
                      <h2>Mentioned On</h2>
                    </div>
                    <div className="logo-icon-pack">
                      <img src={newslogo1}></img>
                      <img src={newslogo2}></img>
                      <img src={newslogo3}></img>
                      <img src={newslogo4}></img>
                      <img src={newslogo5}></img>
                      <img src={newslogo6}></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section1">
            <div className="container">
              <div className="row">
                <div className="section-1-content">
                  <h1>
                    Less than 24 hours left to enroll in benefits in{" "}
                    {cityStateResponse.state}
                  </h1>
                  <p>
                    Thanks to a new government subsidiary program,{" "}
                    {cityStateResponse.state} residents could get $0 Free Health
                    Insurance and up to $1400* allowance card for the everyday
                    expense including No-Cost Vision & No-Cost Dental Benefits.
                  </p>
                </div>

                <div className="item-benefits">
                  <div className="card">
                    <img src={groceryImage} alt="" />
                    <div className="card-content">
                      <div className="benefitImage">
                        <img
                          src={groceryImage1}
                          className="cardimg grocery"
                        ></img>
                      </div>
                      <div className="benefitHeadline">
                        <h3>Groceries</h3>
                        <p>
                          Make your grocery budget go further with the $6400
                          Subsidy Card. Enjoy the freedom to fill your cart with
                          essentials and more, without the pinch on your wallet.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <img src={shoppingImage} alt="" />
                    <div className="card-content">
                      <div className="benefitImage">
                        <img
                          src={shoppingImage1}
                          className="cardimg shopping"
                        ></img>
                      </div>
                      <div className="benefitHeadline">
                        <h3>Bills</h3>
                        <p>
                          Manage your utility and other bills with ease. The
                          Subsidy Card is your ally in keeping the lights on and
                          the water running without financial stress.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <img src={gasImage} alt="" />
                    <div className="card-content">
                      <div className="benefitImage">
                        <img src={gasImage1} className="cardimg gas"></img>
                      </div>
                      <div className="benefitHeadline">
                        <h3>Car Gas</h3>
                        <p>
                          Keep your tank full and your travels worry-free. The
                          Subsidy Card takes the edge off commuting costs,
                          fueling your way to work and beyond.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <img src={rentImage} alt="" />
                    <div className="card-content">
                      <div className="benefitImage">
                        <img src={rentImage1} className="cardimg rent"></img>
                      </div>
                      <div className="benefitHeadline">
                        <h3> Home Rent</h3>
                        <p>
                          Stabilize your living situation with our Subsidy Card.
                          Relieve the pressure of rent and focus on what truly
                          matters in your home and life.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cta-holder">
                  <Link to={"income"} id="cta-click" onClick={gotoTop}>
                    I Want My Card
                  </Link>
                  <span>37 Available Slots Left, Act Now!</span>
                </div>
              </div>
            </div>
          </div>
          <div className="section2">
            <div className="container">
              <div className="row">
                <div className="section2-headline-container">
                  <div className="section2-headline">
                    <h1>
                      Transforming Lives: The Impact of the Subsidy Program
                    </h1>
                    <p>
                      Dive into the remarkable journey of the Subsidy Program, a
                      pivotal initiative reshaping the financial landscape for
                      low-wage Americans. Witness the significant strides made
                      in providing essential support for groceries, rent, gas,
                      and bills. Here's a snapshot of the monumental impact and
                      ongoing progress:
                    </p>
                  </div>
                </div>
                <div className="section2-stat-counter">
                  <div className="statCounter">
                    <div className="statCounterStats">
                      <h3>$43 Million</h3>
                      <h4>Subsidies</h4>
                    </div>
                    <div className="statCounterIntro">
                      <p>
                        The ripple effect of financial empowerment is evident,
                        with millions allocated to uplift households across the
                        nation.
                      </p>
                    </div>
                  </div>
                  <div className="statCounter">
                    <div className="statCounterStats">
                      <h3>250,000+</h3>
                      <h4>People</h4>
                    </div>
                    <div className="statCounterIntro">
                      <p>
                        Many lives have been touched, with more families joining
                        every day to avail the comprehensive benefits
                      </p>
                    </div>
                  </div>
                  <div className="statCounter">
                    <div className="statCounterStats">
                      <h3>1 in 3</h3>
                      <h4>Population</h4>
                    </div>
                    <div className="statCounterIntro">
                      <p>
                        Reflecting the widespread need, a third of the
                        population qualifies for this life-enhancing program,
                        ensuring no one is left behind.
                      </p>
                    </div>
                  </div>
                  <div className="statCounter">
                    <div className="statCounterStats">
                      <h3>482</h3>
                      <h4>Seats</h4>
                    </div>
                    <div className="statCounterIntro">
                      <p>
                        With limited spots remaining, urgency meets opportunity
                        for those aiming to harness the program's full
                        potential.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="testimonial">
            <div className="container">
              <div className="row">
                <div className="testimonial-headline">
                  <h1>Try Yourself Today!</h1>
                  <div className="testimonial-content-holder">
                    <p>
                      We strive to offer you the best service, as evidenced by
                      our Trustpilot star rating.
                    </p>
                    <img src={testimonial} alt="" />
                  </div>
                </div>

                <div className="testimonial-list">
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <img src={user1} className="userimg"></img>

                      <div className="testimonial-top">
                        <div className="user">
                          <h3>Floyd Miles</h3>
                        </div>
                        <div className="star-rating">
                          <img src={star} alt="" />
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <p>
                          I just received mine fast! They worked tirelessly to
                          secure the financial subsidy I needed, and their
                          dedication to customer satisfaction is unmatched.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <img src={user2} className="userimg"></img>

                      <div className="testimonial-top">
                        <div className="user">
                          <h3>Michael Anderson</h3>
                        </div>
                        <div className="star-rating">
                          <img src={star} alt="" />
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <p>
                          Just in time when i need it the most! I can't thank US
                          Support enough for their prompt assistance. Their
                          team's commitment to me is truly commendable.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section3">
            <div className="container">
              <div className="row">
                <div className="faq">
                  <h2>Frequently Asked Questions</h2>
                  <p>
                    Get quick answers to your most pressing questions about our
                    subsidy programs below. If you need more information, please
                    contact us.
                  </p>
                </div>

                <div className="accordion">
                  {accordionData.map(({ title, content, id }) => (
                    <Accordion key={id} title={title} content={content} />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="container top-footer">
              <div className="row">
                <div className="footer-content">
                  <p>
                    {window.domain_settings.websiteName} is not an insurance or
                    operating company but connects individuals with insurance
                    providers and other affiliates. Plans are insured or covered
                    by a Medicare Advantage organization with a Medicare
                    contract and/or a Medicare-approved Part D
                    sponsor.Enrollment in the plan depends on the plan’s
                    contract renewal with Medicare. Possible options include,
                    but are not limited to Major Medical Plans, Short Term
                    Plans, Christian Health Plans, Health Sharing Plans,
                    discount cards and Fixed Indemnity Plans.Descriptions are
                    for informational purposes only and subject to change.
                    {" " + window.domain_settings.websiteName} is not affiliated
                    with any insurance plan nor does it represent or endorse any
                    plan. We do not offer every plan available in your area. Any
                    information we provide is limited to those plans we do offer
                    in your area. Please contact Medicare.gov or 1–800 MEDICARE
                    to get information on all of your options. By using this
                    site, you acknowledge that you have read and agree to the
                    Terms of Service, and Privacy Policy. Not affiliated with
                    the U. S. government or federal Medicare program. A licensed
                    agent may contact you regarding this insurance-related
                    information.
                  </p>
                </div>
              </div>
            </div>
            <div className="container bottom-footer">
              <div className="row">
                <div className="footer-navigation">
                  <div className="footer-copyright">
                    <p>
                      © Copyright {window.domain_settings.websiteTitle} 2023
                    </p>
                  </div>
                  <div className="footer-navigation-wrapper">
                    <ul>
                      <li>
                        <a href="/privacy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/terms">Terms of Use</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FloatingCard />
      <LanderSocialEventTrigger />
    </>
  );
}

function LanderSocialEventTrigger() {
  const { getEventId } = useEventID();

  const viewContentKey = "PageView";
  const viewContentKey2 = "PageView2";

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  useEffect(() => {
    if (window.fbcFunc) {
      window.fbcFunc("track", "PageView", { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey, "true");
    }

    if (window.tikTokEvent && params.get("utm_source") === "tiktok") {
      window.tikTokEvent.track("ViewContent", {}, { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey2, "true");
    }
  }, []);
  return <></>;
}
